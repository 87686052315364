import { decorate, observable, action, computed } from "mobx";
import { fetchData } from "../services/Api";
import Theme from "./Theme";
import DataCruncher from "./DataCruncher";
import QuestionStore from "./QuestionStore";
import ConfigStore from "./ConfigStore";

import User from "./User";
import Answer from "./Answer";

import GeoAnswer from "./GeoAnswer";
import Chapter from "./Chapter";
import { Map } from "core-js";
import Report from "./Report";

class Store {
  //temp 'database' location
  path;

  respondentenOverride = null;
  //stores
  questionStore;
  configStore;

  //helpers
  cruncher;
  theme;

  report = null;
  answers = [];
  geoAnswers = [];
  chapters = [];

  users = new Map();
  colorIndex = 0;
  //observables
  userFilterActive = true;

  activeChapter = null;
  loading = true;

  disposer = null;

  constructor(path) {
    this.path = path; //TEMP 'database' solution
    this.questionStore = new QuestionStore(this);
    this.configStore = new ConfigStore(this);
    this.cruncher = new DataCruncher(this);
    this.theme = new Theme();
    this.report = new Report(this);
  }

  setActiveChapter(chapter) {
    this.activeChapter = chapter;
  }

  async load() {
    try {
      await this.questionStore.load(`./conf/${this.path}/questions.json`);
      await this.configStore.load(`./conf/${this.path}/conf.json`);

      if (this.configStore.config.respondentenOverride) {
        this.respondentenOverride = this.configStore.config.respondentenOverride;
      }

      this.chapters = this.configStore.config.chapters.map((c) => new Chapter(this, c));
      this.activeChapter = this.chapters[0];

      if (this.configStore.config.src) {
        await this.loadData(this.configStore.config.src);
        if (window.location.hash && window.location.hash.indexOf("auto") > -1) {
          this.disposer = setInterval(() => {
            console.log("Tick");
            this.loadData(this.configStore.config.src);
          }, 10000);
        }
      } else {
        await this.loadData(`/conf/${this.path}/data.json`);
        this.questionStore.questions.forEach((q) => {
          q.makeCutoff();
        });
      }
    } catch (err) {
      console.error("Store.load error:", err);
    }
    this.loading = false;
  }

  get filteredUsers() {
    if (this.activeUser !== null && this.userFilterActive) {
      return [this.activeUser];
    }
    if (!this.questionStore.filters.length) return Array.from(this.users.values());

    let result = [];
    this.users.forEach((user) => {
      if (user.matchesFilters(this.questionStore.filters)) {
        result.push(user);
      }
    });
    console.log("Filtered users:", result.length);
    return result;
  }

  get filteredAnswers() {
    let result = [];
    this.filteredUsers.forEach((user, key, map) => {
      result.push(...user.answers);
    });

    console.log("Filtered answers:", result.length);
    return result;
  }

  loadData(path) {
    this.answers = [];
    return fetchData(path).then(
      action((data) => {
        this.answers = [];

        data.answers.forEach((a) => {
          let q = this.questionStore.getQuestionByKey(a.question);
          if (q) {
            let answer = new Answer(a, q);
            this.mapUser(answer);
            this.answers.push(answer);
          }
        });

        this.geoAnswers = [];

        data.geoAnswers.forEach((a) => {
          let q = this.questionStore.getGeoQuestionByKey(a.buttonname);
          if (q) {
            let answer = new GeoAnswer(a, q);
            this.mapUser(answer, true);
            this.geoAnswers.push(answer);
          }
        });

        if (data.answerFiles) {
          data.answerFiles.forEach((af) => {
            let ga = this.geoAnswers.find((ga) => ga.id === af.ref_id);
            if (ga) {
              ga.files.push(af);
            }
          });
        }
      })
    );
  }

  mapUser(answer, isGeo = false) {
    let user = this.users.get(answer.userId);
    if (!user) {
      user = new User(this.configStore.config.expressions, answer.userId);
      this.users.set(answer.userId, user);
    }
    if (isGeo) {
      user.addGeoAnswer(answer);
    } else {
      user.addAnswer(answer);
    }
  }

  getGeoQuestionColor() {
    let result = this.theme.pickChartColor(this.colorIndex);
    this.colorIndex++;
    return result;
  }

  setActiveUser(user) {
    if (user.active) {
      user.active = false;
      return;
    }
    this.users.forEach((u) => {
      if (u.id !== user.id) {
        u.active = false;
      }
    });
    user.active = true;
  }

  toggleUserIds(ids) {
    console.log(ids);
    ids.forEach((id) => {
      let user = this.users.get(id);
      if (user) {
        user.active = !user.active;
      }
    });
  }

  get activeUser() {
    let result = null;
    this.users.forEach((u) => {
      if (u.active) result = u;
    });
    return result;
  }

  get activeUsers() {
    let result = [];
    this.users.forEach((u) => {
      if (u.active) result.push(u);
    });
    return result;
  }
}

export default decorate(Store, {
  activeChapter: observable,
  loading: observable,
  setActiveChapter: action,
  filteredUsers: computed,
  filteredAnswers: computed,
  activeUser: computed,
  activeUsers: computed,
  setActiveUser: action,
  toggleUserIds: action,
  userFilterActive: observable
});
