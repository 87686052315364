import React from "react";
import ReactDOM from "react-dom";
import "./css/reset.css";
import App from "./App";
import { Provider } from "mobx-react";
import registerServiceWorker from "./registerServiceWorker";
import "mobx-react-lite/batchingForReactDom";
import Store from "./store/Store";
import UIStore from "./store/UIStore";
const store = new Store("soest");
const uiStore = new UIStore();

ReactDOM.render(
  <Provider store={store} ui={uiStore}>
    <App />
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
